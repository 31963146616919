<template>
    <div>
        <Loader v-if="loading" />
        <v-data-table
            v-else
            :headers="headers"
            :items="bots"
            :items-per-page="5"
            class="elevation-1"
        ></v-data-table>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import Loader from '../components/ui/Loader.vue'

    export default {
        name: 'BotList',
        components : {
            Loader
        },
        data : () => ({
            loading : true,
        }),
        async mounted() {
            await this.$store.dispatch('load_bots')
            this.loading = false
        },
        computed: {
            ...mapGetters(['bots',]),
            headers() {
                return [
                    {
                        text : 'id',
                        align : 'start',
                        value : 'id'
                    },
                    {
                        text : 'Имя бота',
                        value : 'full_name'
                    },
                    {
                        text : 'Токен бота',
                        value : 'token'
                    },
                    {
                        text : 'Username',
                        value : 'username'
                    },
                ]
            }
        }
    }

</script>